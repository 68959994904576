import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import queryString from 'query-string';

import useCanShareReward from 'hooks/use-can-share-reward';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';
import { SkeletonAccountPage } from 'views/account/skeleton';

import { useNavigationContext } from 'contexts/navigation-context';

import { isAuthenticated } from 'services/authentication-service';
import { getPassesById } from 'state/selectors';

const LazyLoyaltyRedeemNav = loadable(() => import('suspended-page/loyalty-card/loyalty'));

const LoyaltyCardViewPage = ({ location = {} }) => {
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const [title, setTitle] = useState('');
  const { canReward, canShareRewardLoading } = useCanShareReward();

  useEffect(() => {
    const onFindPass = async () => {
      const { name = '' } = await getPassesById(passId);
      setTitle(name);
    };
    onFindPass();
    // eslint-disable-next-line
  }, []);

  const {
    homePageOnly = () => {},
    gotoAccount = () => {},
  } = useNavigationContext();
  const authenticated = isAuthenticated();

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (!canReward) {
    gotoAccount();
    return (<SkeletonAccountPage />);
  }

  return (<LazyLoyaltyRedeemNav passId={passId} title={title} loading={canShareRewardLoading}/>);
};

LoyaltyCardViewPage.propTypes = {
  location: object,
};

export default LoyaltyCardViewPage;
